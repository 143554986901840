<template>
  <div id="QuestionDetail">
    <div class="mainTop">
      <div>
        <p class="second">{{quizDetail.question}}</p>
        <p class="third">{{quizDetail.question_describe}}</p>
        <div class="forth">
          <div><img :src="quizDetail.quiz_user.headimgurl ?Global.changeImg(quizDetail.quiz_user.headimgurl) :'../../assets/image/home/image1.png'" /><span>{{quizDetail.quiz_member_info.name}}</span></div>
          <div>
            <span><span>{{quizDetail.quiz_answer_count}}</span>回答</span>
            <!-- <span><span>{{quizDetail.circusee_count}}</span>围观</span> -->
          </div>
        </div>
      </div>
    </div>
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
      class="hotMain hotMain1"
    >
      <div v-for="(i,item) in getDocList" :key="item">
        <div>
          <div class="first">
            <div>
              <img :src="i.user.headimgurl ?Global.changeImg(i.user.headimgurl) :'../../assets/image/home/image1.png'"  />
              <span>{{i.member_info.name}}</span>
            </div>
            <span></span>
          </div>
          <p class="sixth">{{i.upvote}}人赞同了该回答</p>
          <p class="third"><img src="../../assets/image/home/answer.png" />{{i.answer}}</p>
          <div class="forth" v-if="i.user.tags.length">
            <div>
              <span v-for="(j,items) in i.user.tags" :key="items">{{j.name}}</span>
            </div>
            <div>
              <!-- <img v-for="(j,items) in i.circusee"  :key="items" :src="Global.changeImg(j.head_img.headimgurl)" />  等{{i.circusee_count}}人已围观 -->
            </div>
          </div>
          <div class="fifth">
            <div class="two">
              <span>
                  {{i.upvote}}
                  <van-icon @click.stop="noLike(i.id,item)" v-if="i.upvote_log.length" name="good-job" style="font-size:0.15rem;margin-left:0.05rem"  color="#FF5562" />
                  <van-icon @click.stop="toLike(i.id,item)"  v-else name="good-job-o" style="font-size:0.15rem;margin-left:0.05rem"  color="#FF5562" />
                </span>
                <span @click.stop="toStar(i.id)">
                  {{i.commentCount}} 
                  <van-icon  name="comment-o" style="font-size:0.15rem;margin-left:0.05rem"  color="#3890FF" />
                  <!-- <img v-for="(j,items) in i.circusee"  :key="items" :src="Global.changeImg(j.head_img.headimgurl)" /> 等{{i.circusee_count}}人已围观 -->
                </span>
            </div>
            <div class="one">
              {{Global.clearWhite($moment(new Date(Date.parse((i.created_at)))).fromNow())}}
            </div>
          </div>
        </div>
       
      </div>
    </van-list>
    
    <div class="toSubmit" @click="toAnswer" v-if="!quizDetail.answer"><div>我来回答</div></div>
     <van-popup v-model="showEd" position="bottom" :style="{ height: '90%' }"  >
       <!-- 评论 -->
        <van-list
          v-model="loading1"
          :finished="finished1"
          finished-text="没有更多了"
          @load="onLoad1"
          class="hotMain" style="padding-bottom:0.6rem"
        >
          <div>
            <div v-for="(j,items) in getQuizComment" :key="items">
              <div class="first">
                <div>
                  <img :src="j.user.headimgurl ?Global.changeImg(j.user.headimgurl) :'../../assets/image/home/image1.png'"  />
                  <span>{{j.member_info.name}}</span>
                </div>
                <span></span>
              </div>
              <p class="third">{{j.content}}</p>
              <div class="fifth">
                <div class="one">
                  {{Global.clearWhite($moment(new Date(Date.parse((j.created_at)))).fromNow())}}
                </div>
                <div  class="two">
                  <span>
                      {{j.upvote}}
                      <van-icon @click.stop="noComLike(j.id,items,4)" v-if="j.upvote_log.length" name="good-job" style="font-size:0.15rem;margin-left:0.05rem"  color="#FF5562" />
                      <van-icon @click.stop="toComLike(j.id,items,4)"  v-else name="good-job-o" style="font-size:0.15rem;margin-left:0.05rem"  color="#FF5562" />
                      <!-- @click.stop="toStar(i.id)" -->
                    </span>
                    <span @click.stop="commentId = j.id;showEdTwo = true;replyUserId = j.user_id">
                      {{j.commentCount}} 
                      <van-icon  name="comment-o" style="font-size:0.15rem;margin-left:0.05rem"  color="#3890FF" />
                      <!-- <img v-for="(j,items) in i.circusee"  :key="items" :src="Global.changeImg(j.head_img.headimgurl)" /> 等{{i.circusee_count}}人已围观 -->
                    </span>
                </div>
              </div>
              <!-- 评论的评论 -->
              <div v-if="j.quiz_reply_comment.length" style="padding-left:0.25rem">
                <div v-for="(k,itemk) in j.quiz_reply_comment" :key="itemk">
                  <div class="first">
                    <div>
                      <img :src="k.user.headimgurl ?Global.changeImg(k.user.headimgurl) :'../../assets/image/home/image1.png'"  />
                      <span>{{k.member_info.name}} <span style="color:#a5a5a5">回复</span></span>{{k.member.name}}
                    </div>
                    <span></span>
                  </div>
                  <p class="third">{{k.content}}</p>
                  <div class="fifth">
                    <div class="one">
                      {{Global.clearWhite($moment(new Date(Date.parse((k.created_at)))).fromNow())}}
                    </div>
                    <div class="two">
                        <span>
                          {{k.upvote}}
                          <van-icon @click.stop="noComLike(k.id,items,8,itemk)" v-if="k.upvote_log.length" name="good-job" style="font-size:0.15rem;margin-left:0.05rem"  color="#FF5562" />
                          <van-icon @click.stop="toComLike(k.id,items,8,itemk)"  v-else name="good-job-o" style="font-size:0.15rem;margin-left:0.05rem"  color="#FF5562" />
                          <!-- @click.stop="toStar(i.id)" -->
                        </span>
                        <span @click.stop="commentId = j.id;showEdTwo = true;replyUserId = k.user_id">
                          {{k.commentCount}} 
                          <van-icon  name="comment-o" style="font-size:0.15rem;margin-left:0.05rem"  color="#3890FF" />
                          <!-- <img v-for="(j,items) in i.circusee"  :key="items" :src="Global.changeImg(j.head_img.headimgurl)" /> 等{{i.circusee_count}}人已围观 -->
                        </span>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div class="somEdi">
            <span @click="showEdTwo = true;pAns = true">想说点什么</span>
            <span>发送</span>
          </div>
        </van-list>
    </van-popup>
    <van-popup v-model="showEdTwo" position="bottom" @click="showEdTwo = false" :style="{ height: '90%',background: '#23252E99'}"   >
      <div  class="editSure" @click.stop="">
        <van-field
          class="edit"
          v-model="revert"
          rows="2"
          autosize
          type="textarea"
          placeholder="想说点什么"
          show-word-limit
          style="flex:1"
        />
        <div >
          <span @click="toRever">确定</span>
        </div>
      </div>
    </van-popup>

  </div>
</template>
<script>
import start from '../../assets/image/findOut/start.png'
import startn from '../../assets/image/findOut/startn.png'
export default {
  name: 'QuestionDetail',
   data(){
    return{
      id:'',
      quizDetail:'',
      finished:false,
      finished1:false,

      showEdTwo:false,
      loading:true,
      loading1:true,

      next_page_url:'',
      next_page_url1:'',

      getDocList:'',
      getCollectIds:[],
      getQuizComment:'',
      showEd:false,
      revert:'',//评论内容
      quizId:'',//回答id
      pAns:'',//评论回答
      commentId:'',//评论id
      replyUserId:'',//用户id
      docId:''
    }
  },
  mounted(){
    this.id = this.$route.query.id
    // 围观问答
    // this.axios({
    //   method: 'POST',
    //   url: '/quiz/circuseeQuestion',
    //   headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
    //   data:{
    //     questionId:this.id
    //   }
    // })
    // 问答详情
    this.axios({
      method: 'GET',
      url: '/quiz/problemDetail?quizId='+this.id,
      headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
    }).then((res) => {
      if(res.data.code == 0){
        this.quizDetail = res.data.data
      }
    })
    // 回答列表
     this.axios({
      method: 'GET',
      url: '/quiz/quizDetail?quizId='+this.id,
      headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
    }).then((res) => {
      if(res.data.code == 0){
        this.loading = false
        this.getDocList = res.data.data.data;
        this.next_page_url = res.data.data.next_page_url
      }
    })
  },
  methods:{
    // 点击评论回答
    toRever(){
      if(this.pAns){  //评论回答
        this.axios({
          method: 'POST',
          url: '/quiz/userReplyQuiz',
          data:{
            revert:this.revert,
            quizId:this.quizId,
          },
          headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        }).then((res) => {
          if(res.data.code == 0){
            this.revert = ''
            this.pAns = false
            this.showEdTwo = false
            this.$toast('评论成功,待审核')
            // 评论数目加一
          }
        })
      }else{ //回复评论
        this.axios({
          method: 'POST',
          url: '/quiz/userReplyComment',
          data:{
            commentId:this.commentId,
            revert:this.revert,
            replyUserId:this.replyUserId,
          },
          headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        }).then((res) => {
          if(res.data.code == 0){
            this.revert = ''
            this.showEdTwo = false
            this.$toast('评论成功,待审核')
            // 评论数目加一
          }
        })
      }
    },
    // 展开评论区
    toStar(docId){
      // 如果评论区已展示，就关闭
      this.docId = docId
      if(this.getCollectIds.indexOf(docId) != '-1'){
        this.showEd = false
      }else{ //否则就请求评论
        this.axios({
          method: 'GET',
          url: '/quiz/getQuizComment?replyId='+docId,
          headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        }).then((res) => {
          if(res.data.code == 0){
            this.getQuizComment = res.data.data.data
            this.showEd = true
            this.quizId = docId

            this.loading1 = false
            this.next_page_url1 = res.data.data.next_page_url
          }
        })
      }
    },
    
    noComLike(docId,item,type,items){
      this.axios({
          method: 'PATCH',
          url: '/quiz/upvoteQuiz',
          data:{
            status:0,
            type:type,
            linkId:docId
          },
          headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        }).then((res) => {
          if(res.data.code == 0){
            if(type == 4){
              this.getQuizComment[item].upvote_log = []
              this.getQuizComment[item].upvote--
            }else{
              this.getQuizComment[item].quiz_reply_comment[items].upvote_log = []
              this.getQuizComment[item].quiz_reply_comment[items].upvote--
            }
            // var index = this.getCollectIds.indexOf(docId);
            this.$forceUpdate()
            this.$toast({
              message: '取消点赞',
              icon: startn,
            })
          }
        })
    },
    toComLike(docId,item,type,items){
      this.axios({
        method: 'PATCH',
        url: '/quiz/upvoteQuiz',
        data:{
          status:1,
          type:type,
          linkId:docId
        },
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      }).then((res) => {
        if(res.data.code == 0){
          if(type == 4){
              this.getQuizComment[item].upvote_log.push([{id:docId}])
              this.getQuizComment[item].upvote++
            }else{
              this.getQuizComment[item].quiz_reply_comment[items].upvote_log.push([{id:docId}])
              this.getQuizComment[item].quiz_reply_comment[items].upvote++
            }
          this.$forceUpdate()
          this.$toast({
            message: '点赞成功',
            icon: start,
          })
        }
      })
    },
    // 点赞
    noLike(docId,index){
      this.axios({
          method: 'PATCH',
          url: '/quiz/upvoteQuiz',
          data:{
            status:0,
            type:6,
            linkId:docId
          },
          headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        }).then((res) => {
          if(res.data.code == 0){
            // var index = this.getCollectIds.indexOf(docId);
            this.getDocList[index].upvote_log = []
            this.getDocList[index].upvote--
            this.$toast({
              message: '取消点赞',
              icon: startn,
            })
          }
        })
    },
    toLike(docId,index){
      this.axios({
        method: 'PATCH',
        url: '/quiz/upvoteQuiz',
        data:{
          status:1,
          type:6,
          linkId:docId
        },
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      }).then((res) => {
        if(res.data.code == 0){
          this.getDocList[index].upvote_log.push([{id:docId}])
          this.getDocList[index].upvote++
          this.$toast({
            message: '点赞成功',
            icon: start,
          })
        }
      })
    },
    toAnswer(){
      this.$router.push({
        path: '/QuestionAnswer?id='+this.id,
      })
    },
    onLoad1(){
      if(this.next_page_url1){
        this.axios({
          method: 'GET',
          url: this.next_page_url1 + '&replyId='+this.docId,
          headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        }).then((res) => {
          if(res.data.code == 0){
            this.loading1 = false
            if(res.data.data){
              this.getQuizComment.push(...res.data.data.data)
              this.next_page_url1 = res.data.data.next_page_url1
            }else{
              this.next_page_url1 = ''
            }
            if(!this.next_page_url1 || !res.data.data.data.length || !res.data.data){
              this.finished1 = true
            } 
          }
        })
      }else{
        this.loading1 = false;
        this.finished1 = true
      }
    },
    onLoad(){
      if(this.next_page_url){
        this.axios({
          method: 'GET',
          url: this.next_page_url + '&quizId='+this.id,
          headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        }).then((res) => {
          if(res.data.code == 0){
            this.loading = false
            if(res.data.data){
              this.getDocList.push(...res.data.data.data)
              this.next_page_url = res.data.data.next_page_url
            }else{
              this.next_page_url = ''
            }
            if(!this.next_page_url || !res.data.data.data.length || !res.data.data){
              this.finished = true
            } 
          }
        })
      }else{
        this.loading = false;
        this.finished = true
      }
    },
  }
}
</script>
<style lang="less" scoped>
#QuestionDetail{
  .somEdi{
    display: flex;
    position: fixed;
    bottom: 0;
    background: #383a44;
    width: 100%;
    color: #F6F7FD;
    padding-top: 0.18rem !important;
    justify-content: space-between;
    box-sizing: border-box;
    >span:first-child{
      flex: 1;
    }
    >span:last-child{
      color: #3890FF;
    }
  }
  .editSure{
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #16171D;
    box-sizing: border-box;
    padding: 0.11rem 0.15rem 0.11rem;
    >div:last-child{
      text-align: right;
      >span{
        margin-top: 0.1rem;
        height: 0.3rem;
        line-height: 0.3rem;
        border-radius: 0.3rem;
        padding: 0 0.2rem;
        background: #3890FF;
        color: #F6F7FD;
        font-size: 0.13rem;
        display: inline-block;
      }
    }
  }
 
  .toSubmit{
    height: 0.42rem;
    background: none;
    color: #F6F7FD;
    line-height: 0.42rem;
    text-align: center;
    width: 100%;
    position: fixed;
    bottom: 0.2rem;
    padding: 0 0.15rem;
    box-sizing: border-box;
    max-width: 750px;
    >div{
      background: #3890FF;
      border-radius: 5px;
    }
  }
  >div{
    background: #23252E;
  }
  .mainTop{
    padding: 0.15rem;
    >div{
      .fifth{
        background: #3D404F;
        border-radius: 0.02rem;
        padding: 0.11rem 0.2rem;
        display: flex;
        justify-content: flex-start;
        margin: 0.15rem 0 0.12rem;
        >div{
          >p:last-child{
            color: #989AB1;
            font-size: 0.12rem;
          }
          >p:first-child{
            color: #F6F7FD;
            font-size: 0.14rem;
            margin-bottom: 0.05rem;
          }
        }
        >img{
          width: 0.3rem;
          margin-right: 0.16rem;
        }
      }
      .forth{
        >div:last-child{
          display: flex;
          >span{
            margin-left: 0.2rem;
            color: #727387;
            display: flex;
            align-items: center;
            font-size: 0.12rem;
            margin-left: 0.2rem;
            >span{
              color: #F6F7FD;
              font-size: 0.12rem;
              margin-right: 0.05rem;
            }
          }
        }
        >div:first-child{
          color: #989AB1;
          display: flex;
          align-items: center;
          >span{
            font-size: 0.12rem;
            margin: 0 0.1rem 0 0.08rem;
          }
          >img{
            width: 0.19rem;
            border-radius: 50%;
          }
        }
      }
      .third{
        color: #989AB1;
        font-size: 0.12rem;
        line-height: 0.17rem;
        margin-bottom: 0.15rem;
      }
      .second{
        color: #F6F7FD;
        font-size: 0.14rem;
        margin: 0 0 0.12rem;
      }
      .first{
        >div{
          color: #989AB1;
          font-size: 0.14rem;
          display: flex;
          align-items: center;
          >span{
            margin: 0 0.1rem 0 0.08rem;
            
          }
          >img{
            width: 0.27rem;
          }
        }
        >span{
          color: #727387;
          font-size: 0.12rem;
        }
      }
      >div{
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
  .hotMain{
    margin-top: 0.1rem;
    >div{
      padding: 0.1rem 0.15rem 0.18rem;
      border-bottom: .1px solid #5C5D6E61;
      >div{
        .sixth{
          color: #727387;
          margin-bottom: 0.1rem;
          font-size: 0.12rem;
        }
        .fifth{
          padding-left: 0.25rem;
          >.one{
            color: #727387;
            display: flex;
            align-items: center;
            font-size: 0.12rem;
          }
          >.two{
            color: #989AB1;
            display: flex;
            align-items: center;
            font-size: 0.12rem;
            >span{
              margin-left: 0.1rem;
              display: flex;
              align-items: center;
            }
            >img{
              width: 0.14rem;
              border-radius: 50%;
            }
            >img:nth-child(n+2){
              margin-left: -0.03rem;
            }
            >img:last-child{
              margin-right: 0.05rem;
            }
          }
        }
        .forth{
          margin-bottom: 0.1rem;
          padding-left: 0.25rem;
          >div:first-child{
            display: flex;
            >span{
              color: #9091A3;
              padding: 0 0.05rem;
              background: #3D404F;
              border-radius: 0.02rem;
              font-size: 0.12rem;
              margin-right: 0.1rem;
            }
          }
          >div:last-child{
            color: #727387;
            display: flex;
            align-items: center;
            font-size: 0.12rem;
            >img{
              width: 0.14rem;
              border-radius: 50%;
            }
            >img:nth-child(n+2){
              margin-left: -0.03rem;
            }
            >img:last-child{
              margin-right: 0.05rem;
            }
          }
        }
        .third{
          padding-left: 0.25rem;
          color: #F6F7FD;
          font-size: 0.12rem;
          line-height: 0.17rem;
          margin-bottom: 0.15rem;
          >img{
            width: 0.14rem;
            display: inline-block;
            line-height: 0.17rem;
            margin-bottom: -0.02rem;
          }
        }
        .second{
          color: #F6F7FD;
          font-size: 0.14rem;
        }
        .first{
          margin: 0.11rem 0 0.12rem;
          display: flex;
          justify-content: space-between;
          >div{
            color: #727387;
            font-size: 0.12rem;
            display: flex;
            align-items: center;
            >span{
              margin: 0 0.1rem 0 0.08rem;
             
            }
            >img{
              width: 0.19rem;
              border-radius: 50%;
            }
          }
          >span{
            color: #727387;
            font-size: 0.12rem;
          }
        }
        .fifth,.forth,.second,.first{
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }
  }
  .hotMain1{
    >div{
      >div{
        .third,.forth,.fifth{
          padding: 0;
        }
        .first{
          >div{
            >img{
              border-radius: 5px;
            }
            >span{}
              color: #d8d8d8;
              font-weight: 900;
            }
        }
        .fifth{
          >.two{
            >span{
              margin-left: 0;
              margin-right: 0.1rem;
            }
          }
        }
      } 
    } 
  }
}
</style>